@import './overrides.scss';
@import './mixins.scss';

.landing-page {
  .bx--tabs--scrollable .bx--tabs--scrollable {
    flex-direction: column;
  }
  .bx--tabs--scrollable__nav-item {
    width: 200px;
    background-color: $color-gray;
  }
  .bx--tabs--scrollable__nav-link {
    width: 200px;
    border-bottom: none;
    &:focus {
      font-weight: 500 !important;
    }
  }
  .tabs-style-mod {
    .bx--tabs--scrollable__nav-link {
      padding: 0.5rem 1rem;
    }
    .bx--tabs--scrollable__nav-item {
      background-color: #f4f4f4;
      &:hover {
        background-color: #cacaca;
        .bx--tabs--scrollable__nav-link {
          border-bottom: none;
        }
      }
      &--selected {
        background-color: #161616;
        .bx--tabs--scrollable__nav-link {
          color: #fff;
          font-weight: 400;
          border-bottom: none;
        }
      }
    }
  }

  &__title {
    font-size: 2.625rem;
    font-weight: 300;
    line-height: 1.19;
    color: #f4f4f4;
  }

  &__illo {
    max-width: 100%;
  }

  &__banner {
    padding: $spacing-09 $spacing-07 0;
    height: 170px;
    z-index: -1;

    @include landing-page-background;
  }

  &__heading {
    @include carbon--type-style('productive-heading-05');
  }

  &__r2 {
    margin-top: rem(-48px);
    padding: 0 $spacing-07;
  }

  &__tab-content {
    align-items: center;
    padding: 24px 0;
  }

  &__subheading {
    @include carbon--type-style('productive-heading-03');
    @include carbon--font-weight('semibold');
  }

  &__p {
    @include carbon--type-style('productive-heading-03');
    margin-top: $spacing-06;
    margin-bottom: $spacing-08;

    @include carbon--breakpoint-between((320px + 1), md) {
      max-width: 75%;
    }
  }

  &__r3 {
    padding-top: $spacing-09;
    padding-bottom: $spacing-09;
    @include landing-page-background;
  }

  &__label {
    @include carbon--type-style('heading-01');
  }
}

.black_bg {
  background-color: black;
}
.full-height {
  height: 100%;
}
.onRight {
  float: right;
}
.modpadding {
  font-size: 20px;
}
.secondaryColor {
  color: #525252;
}
.tabmargin {
  padding-left: 32px;
  padding-right: 32px;
}
.tabmarginbox {
  padding: 32px 32px 120px;
  position: relative;
  background-color: white;
  .button-submit {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 4rem;
    width: 50%;
  }
  .bx--file-browse-btn {
    margin-bottom: 4px;
  }
}
.tabbottom {
  padding-bottom: 24px;
}

.tabContainer {
  padding-left: 192px;
  padding-right: 192px;
}
.bottomPadding {
  padding-bottom: 24px;
}
.label-size {
  font-size: 14px;
}
.inline {
  display: flex;
  justify-content: space-between;
}
.float-left {
  float: left;
}

.tabs-style {
  left: 32px;
  .bx--tabs__nav-link {
    color: black !important;
  }
  &__title {
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
    line-height: 1.3;
    color: #161616;
  }
  &__des {
    font-size: 0.875rem;
    color: #525252;
    line-height: 1.43;
  }
}
