@import '../node_modules/carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import '../node_modules/carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import '../node_modules/carbon-components/scss/globals/scss/typography.scss';
@import '../node_modules/carbon-components/scss/globals/scss/vars.scss';
@import './component/Header/Header.scss';

@import './styles/variables.scss';

@import './content/LandingPage/_landing-page';
@import './content/Modular/Modular.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.full-Height {
  min-height: 100vh;
  padding: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
