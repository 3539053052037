.center-align {
  align-content: center;
}
.fullwidth {
  width: 100%;
  height: 100%;
}
.svg {
  position: absolute;
  height: 100%;
  width: 100%;
}

.AutoSizer {
  width: 100% !important;
  margin-top: 24px;
}

#SVG-container {
  &.--disabled {
    svg {
      pointer-events: none;
    }
  }
  .entity.pleat {
    .pink-arrow {
      display: none;
    }

    &:hover {
      .pink-arrow {
        display: block;
      }
    }

    &:hover,
    &.active {
      color: #0f62fe;
      text {
        fill: #0f62fe;
      }
      &[data-layer='seamBlock'] ~ text {
        fill: #0f62fe;
      }
      .pink-arrow {
        display: block;
      }
    }
  }

  .svg__toolbar__dropdown {
    position: relative;
    top: 4px;

    &__wrapper {
      display: flex;
      align-items: center;
    }
  }

  .svg__toolbar {
    padding: 0 12px 0;
    background-color: #e0e0e0;
    min-height: 32px;
    position: relative;
    .btn-magic {
      position: absolute;
      right: 242px;
      top: 0;
    }
  }

  div[role='toolbar'] {
    top: unset !important;
    left: unset !important;
    right: 0 !important;
    bottom: 100% !important;
    flex-direction: row !important;
    background: transparent !important;
    padding: 0 !important;
    & > button {
      width: 32px !important;
      height: 32px !important;
      margin: 0 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #0f62fe !important;
      }
    }
  }
}
