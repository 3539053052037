.header-logo {
  padding-left: 16px;
  width: 106px;
}
.boldstyle {
  font-style: Bold;
  font-size: 14px;
  color: white;
  letter-spacing: 0.16px;
}
.normalstyle {
  font-style: normal;
  font-size: 14px;
  color: white;
  letter-spacing: 0.16px;
}

.name {
  margin: 0 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: 0.16px;
  color: #fff;
}
