.fullsize {
  width: 100%;
  height: 736px;
}

.Round-boarder {
  width: 160px;
  height: 40px;
  border-radius: 4px;
  background-color: #f4f4f4;
  border-bottom: none;
}
.bx--tabs .bx--tabs__nav-item--selected {
  background-color: rgba(0, 0, 0, 1);
  color: white;
}
a.bx--tabs__nav-link:active {
  border-bottom: none;
  color: white;
}

.bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link {
  color: white;
  border-bottom: none;
}
.bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--selected):not(.bx--tabs__nav-item--disabled)
  .bx--tabs__nav-link {
  color: rgb(0, 0, 0);
  background-color: rgba(141, 141, 141, 1);
  border-bottom: none;
}
.bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link {
  color: rgb(255, 255, 255);
  background-color: rgba(141, 141, 141, 1);
}
@media (min-width: 42rem) {
  a.bx--tabs__nav-link {
    border-bottom: none;
  }
}

.bx--tabs {
  right: 0;
}
.modular-tab {
  right: 0;
}
.inline {
  position: relative;
  display: inline;
}
.centerAlign {
  position: fixed;
  left: 45%;
}
.bx--data-table--compact tbody tr {
  height: 2.5rem;
}
.align-verticle {
  vertical-align: bottom;
}
.relative {
  position: relative;
}

.PleatDetection {
  &__text-wrap {
    padding-bottom: 16px;
  }
  #innerTabs {
    .bx--tabs__nav {
      top: -64px;
      right: 0;
      left: initial;
    }
  }
  .bx--tabs .bx--tabs__nav-item--selected a {
    color: white !important;
  }
  div[role='tabpanel'] {
    padding: 0;
  }
  .bx--data-table th,
  .bx--data-table td,
  .bx--data-table tbody th {
    background-color: #fff;
  }
  .bx--data-table tbody tr:first-child td {
    border-top: 1px solid #e0e0e0;
  }
}
